// import React, { useEffect, useState } from 'react';
// import { Route, Link, BrowserRouter, Routes } from 'react-router-dom';
// import Dashboard from './Pages/Dashboard';
// import Analytics from './Pages/Analytics';
// import SelectProduct from './Pages/SelectProduct';
// import OrdersPage from './Pages/OrdersPage';
// import DesignUpload from './Pages/DesignUpload'
// import SignupPage from './Pages/Signup';
// import Login from './Pages/Login';
// import ForgetPassword from './Pages/ForegetPassword';
// import Products from './Pages/Products';
// import DesignReview from './Pages/DesignReview';
// import SelectTShirt from './Pages/SelectTshirt';
// import TransactionsPage from './Pages/Transaction';
// import { ModalProvider } from './components/ModalContext'
// import PaidOrdersPage from './Pages/PaidOrdersPage';

// const App = () => {

//   return (
//     <ModalProvider>

//     <BrowserRouter>
      
//       <Routes>
//         <Route path="/OrdersPage" element={<OrdersPage />} />
//         <Route path='/dashboard' element={<Dashboard/>} />
//         <Route path='/signup' element={<SignupPage/>} />
//         <Route path='/' element={<Login/>} />
//         <Route path='/login/forgetPassword' element={<ForgetPassword/>} />
//         <Route path='/Analytics' element={<Analytics/>} />
//         <Route path='/SelectProduct' element={<SelectProduct/>} />
//         <Route path='/SelectTshirt' element={<SelectTShirt/>} />
//         <Route path='/DesignUpload' element={<DesignUpload/>} />
//         <Route path='/DesignReview' element={<DesignReview/>} />
//         <Route path='/Products' element={<Products/>} />
//         <Route path='/Transaction' element={<TransactionsPage/>} />
//         <Route path='/PaidOrders' element={<PaidOrdersPage/>} />
//       </Routes>
//     </BrowserRouter>
//     </ModalProvider>
//   );
// };

// export default App;



// src/App.js
import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import Analytics from './Pages/Analytics';
import SelectProduct from './Pages/SelectProduct';
import OrdersPage from './Pages/OrdersPage';
import DesignUpload from './Pages/DesignUpload';
import SignupPage from './Pages/Signup';
import Login from './Pages/Login';
import ForgetPassword from './Pages/ForegetPassword';
import Products from './Pages/Products';
import DesignReview from './Pages/DesignReview';
import SelectTShirt from './Pages/SelectTshirt';
import TransactionsPage from './Pages/Transaction';
import { ModalProvider } from './components/ModalContext';
import PaidOrdersPage from './Pages/PaidOrdersPage';
import AuthRoute from './components/AuthRoute'; // Import your AuthRoute
import TermsConditions from './Pages/TermsConditions';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import OrderSample from './Pages/OrderSample';
import SampleOrder from './Pages/OrderSample';
import './App.css'
import BookSample from './Pages/BookSample';
import caf from './Pages/caf';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ShippingPolicy from './Pages/ShippingPolicy';
import Cancellation from './Pages/Cancellation';
import ContactUs from './Pages/ContactUs';
import StoreUrlForm from './Pages/StoreUrlForm';

const App = () => {
  return (
    <ModalProvider>
      <BrowserRouter>
        <Routes>

          <Route path="/" element={<StoreUrlForm/>} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/login/forgetPassword" element={<ForgetPassword />} />
           
          {/* Protected Routes */}
          <Route path="/dashboard" element={<AuthRoute element={Dashboard} />} />
          <Route path="/OrdersPage" element={<AuthRoute element={OrdersPage} />} />
          {/* <Route path="/Analytics" element={<AuthRoute element={Analytics} />} /> */}
          <Route path="/SelectProduct" element={<AuthRoute element={SelectProduct} />} />
          <Route path="/SelectTshirt" element={<AuthRoute element={SelectTShirt} />} />
          <Route path="/DesignUpload" element={<AuthRoute element={DesignUpload} />} />
          <Route path="/DesignReview" element={<AuthRoute element={DesignReview} />} />
          <Route path="/Products" element={<AuthRoute element={Products} />} />
          <Route path="/Transaction" element={<AuthRoute element={TransactionsPage} />} />
          <Route path="/PaidOrders" element={<AuthRoute element={PaidOrdersPage} />} />

          <Route path="/terms-and-conditions" element={<TermsConditions/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/shipping-policy" element={<ShippingPolicy/>} />
          <Route path="/cancellation-policy" element={<Cancellation/>} />
          <Route path="/contact-us" element={<ContactUs/>} />

          


          <Route path="/Order-sample" element={<AuthRoute element={SampleOrder} />} />
          <Route path="/Book-a-sample" element={<AuthRoute element={BookSample} />} />
          <Route path="/caf" element={<AuthRoute element={caf} />} />
        </Routes>
      </BrowserRouter>
    </ModalProvider>
  );
};

export default App;
