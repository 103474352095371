// import React from 'react';
// import '../style/Sidebar.css';

// const Sidebar = ({ isOpen, toggleSidebar }) => {
//   return (
//     <aside className={`sidebar ${isOpen ? 'open' : ''}`}>
//       <button className="close-btn" onClick={toggleSidebar}>×</button>
//       <ul className="menu">
//         <li>Dashboard</li>
//         <li>Design Library</li>
//         <li>Order Management</li>
//         <li>List of Products</li>
//         <li>Billing</li>
//         <li>Transaction</li>
//       </ul>
//       <div className="overlay" onClick={toggleSidebar}></div>
//     </aside>
//   );
// };

// export default Sidebar;



// import React, { useState } from 'react';
// import '../style/Sidebar.css';
// import { FaHome, FaChartLine, FaShoppingCart, FaUndo, FaRocket, FaWeight, FaStore, FaCogs, FaUsers, FaFileAlt } from 'react-icons/fa';

// const Sidebar = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   // Toggle the sidebar open state on click
//   const toggleSidebar = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <div
//       className={`sidebar ${isOpen ? 'open' : 'closed'}`}
//       onMouseEnter={() => setIsOpen(true)}  // Open on hover
//       onMouseLeave={() => setIsOpen(false)} // Close on mouse leave
//       onClick={toggleSidebar} // Toggle open/close on click
//     >
//       <div className="logo">
//         <div className="icon">{/* Logo Icon here */}</div>
//         {isOpen && <span className="logo-text">Unitee</span>}
//       </div>
//       <div className="menu">
//         <div className="menu-item">
//           <FaHome className="icon" />
//           {isOpen && <span>Home</span>}
//         </div>
//         <div className="menu-item">
//           <FaChartLine className="icon" />
//           {isOpen && <span>Dashboard</span>}
//         </div>
//         <div className="menu-item">
//           <FaShoppingCart className="icon" />
//           {isOpen && <span>Orders</span>}
//         </div>
//         <div className="menu-item">
//           <FaUndo className="icon" />
//           {isOpen && <span>Returns</span>}
//         </div>
//         <div className="menu-item">
//           <FaRocket className="icon" />
//           {isOpen && <span>Quick - Instant Delivery</span>}
//         </div>
//         <div className="menu-item">
//           <FaRocket className="icon" />
//           {isOpen && <span>Delivery Boost</span>}
//         </div>
//         <div className="menu-item">
//           <FaWeight className="icon" />
//           {isOpen && <span>Weight Management</span>}
//         </div>
//         <div className="menu-item">
//           <FaStore className="icon" />
//           {isOpen && <span>Apps Store</span>}
//         </div>
//         <div className="menu-item">
//           <FaCogs className="icon" />
//           {isOpen && <span>Setup & Manage</span>}
//         </div>
//         <div className="menu-item">
//           <FaUsers className="icon" />
//           {isOpen && <span>Buyer Experience</span>}
//         </div>
//         <div className="menu-item">
//           <FaFileAlt className="icon" />
//           {isOpen && <span>Billing</span>}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Sidebar;





// import React, { useState } from 'react';
// import '../style/Sidebar.css';
// import { FaHome, FaChartLine, FaShoppingCart, FaCheckCircle, FaRocket, FaShapes, FaMoneyCheckAlt, FaFileContract, FaLock, FaPhone, FaTshirt } from 'react-icons/fa';
// import { Link, useLocation } from 'react-router-dom';

// const Sidebar = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [isFounderOpen, setIsFounderOpen] = useState(false); 
//   const location = useLocation();

//   const queryParams = new URLSearchParams(location.search);
//   const shop = queryParams.get('shop');

//   // Toggle the sidebar open state on click
//   const toggleSidebar = () => {
//     setIsOpen(!isOpen);
//   };


//   const toggleFounderInfo = () => {
//     setIsFounderOpen(!isFounderOpen);
//   };

//   return (
//     <div
//       className={`sidebar ${isOpen ? 'open' : 'closed'}`}
//       onMouseEnter={() => setIsOpen(true)}  // Open on hover
//       onMouseLeave={() => setIsOpen(false)} // Close on mouse leave
//       onClick={toggleSidebar} // Toggle open/close on click
//     >
//       <div className="logo">
//         <div className="icon">{/* Logo Icon here */}</div>
//         {/* {isOpen && <span className="logo-text">Unitee</span>} */}
//       </div>
//       <div className="menu">
       
//         <div className="menu-item">
//           <Link to={`/dashboard?shop=${encodeURIComponent(shop)}`} >
//             <FaChartLine className="icon" />
//             {isOpen && <span>Dashboard</span>}
//           </Link>
//         </div>
//         <div className="menu-item">
//           <Link to={`/Products?shop=${encodeURIComponent(shop)}`} >
//             <FaShapes className="icon" />
//             {isOpen && <span>Products</span>}
//           </Link>
//         </div>
//         <div className="menu-item">
//           <Link to={`/Book-a-sample?shop=${encodeURIComponent(shop)}`} >
//             <FaTshirt className="icon" />
//             {isOpen && <span>Book a Sample</span>}
//           </Link>
//         </div>
//         <div className="menu-item">
//           <Link to={`/SelectTshirt?shop=${encodeURIComponent(shop)}`} >
//             <FaRocket className="icon" />
//             {isOpen && <span>Create Product</span>}
//           </Link>
//         </div>
//         <div className="menu-item">
//           <Link to={`/OrdersPage?shop=${encodeURIComponent(shop)}`} >
//             <FaShoppingCart className="icon" />
//             {isOpen && <span>Orders</span>}
//           </Link>
//         </div>
//         <div className="menu-item">
//           <Link to={`/PaidOrders?shop=${encodeURIComponent(shop)}`} >
//             <FaCheckCircle className="icon" />
//             {isOpen && <span>Confirmed Orders</span>}
//           </Link>
//         </div>
//         <div className="menu-item">
//           <Link to={`/Transaction?shop=${encodeURIComponent(shop)}`} >
//             <FaMoneyCheckAlt className="icon" />
//             {isOpen && <span>Transactions</span>}
//           </Link>
//         </div>
//         <div className="menu-item">
//           <Link to={`/terms-and-conditions?shop=${encodeURIComponent(shop)}`} >
//             <FaFileContract className="icon" />
//             {isOpen && <span>Terms and Conditions</span>}
//           </Link>
//         </div>
//         <div className="menu-item">
//           <Link to={`/privacy-policy?shop=${encodeURIComponent(shop)}`} >
//             <FaLock className="icon" />
//             {isOpen && <span>Privacy Policy</span>}
//           </Link>
//         </div>
//         <div className="menu-item">
//           <div onClick={toggleFounderInfo} className="founder-link">
//             <FaPhone className="icon" />
//             {isOpen && <span>Call a Founder</span>}
//           </div>
//           {isFounderOpen && (
//             <div className="founder-info">
//               <p>Name: Rajprit Sahni</p> {/* Replace with actual name */}
//               <p>Phone: +91 81699 32616</p> {/* Replace with actual phone number */}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Sidebar;


import React, { useState } from 'react';
import '../style/Sidebar.css';
import { FaHome, FaEnvelope , FaUndo, FaShippingFast, FaQuestionCircle, FaChartLine, FaShoppingCart, FaCheckCircle, FaRocket, FaShapes, FaMoneyCheckAlt, FaFileContract, FaLock, FaPhone, FaTshirt, FaGavel, FaPhoneAlt, FaVoicemail } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {
  const [isFounderOpen, setIsFounderOpen] = useState(false); 
  const [isHelpOpen, setIsHelpOpen] = useState(false); 
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); 
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const shop = queryParams.get('shop');

  const toggleSidebar = () => { 
    setIsSidebarOpen(!isSidebarOpen); // Toggle the sidebar state
  };

  const toggleFounderInfo = () => {
    setIsFounderOpen(!isFounderOpen);
  };

  const toggleHelpInfo = () => {
    setIsHelpOpen(!isHelpOpen);
  };

  return (
    <div className="sidebar open">
      <div className="logo">
        <div className="icon">{/* Logo Icon here */}</div>
        {/* {isOpen && <span className="logo-text">Unitee</span>} */}
      </div>

      {/* <div className="hamburger-menu" onClick={toggleSidebar}>
        ☰ 
      </div> */}
      
      <div className="menu">
        <div className="menu-item">
          <Link to={`/dashboard?shop=${encodeURIComponent(shop)}`} >
            <FaChartLine className="icon" />
            <span>Dashboard</span>
          </Link>
        </div>
        <div className="menu-item">
          <Link to={`/Products?shop=${encodeURIComponent(shop)}`} >
            <FaShapes className="icon" />
            <span>Products</span>
          </Link>
        </div>
        <div className="menu-item">
          <Link to={`/Book-a-sample?shop=${encodeURIComponent(shop)}`} >
            <FaTshirt className="icon" />
            <span>Book a Sample</span>
          </Link>
        </div>
        <div className="menu-item">
          <Link to={`/SelectTshirt?shop=${encodeURIComponent(shop)}`} >
            <FaRocket className="icon" />
            <span>Create Product</span>
          </Link>
        </div>
        <div className="menu-item">
          <Link to={`/OrdersPage?shop=${encodeURIComponent(shop)}`} >
            <FaShoppingCart className="icon" />
            <span>Orders</span>
          </Link>
        </div>
        <div className="menu-item">
          <Link to={`/PaidOrders?shop=${encodeURIComponent(shop)}`} >
            <FaCheckCircle className="icon" />
            <span>Confirmed Orders</span>
          </Link>
        </div>
        <div className="menu-item">
          <Link to={`/Transaction?shop=${encodeURIComponent(shop)}`} >
            <FaMoneyCheckAlt className="icon" />
            <span>Transactions</span>
          </Link>
        </div>
       
        <div className="menu-item">
          <Link to={`/privacy-policy?shop=${encodeURIComponent(shop)}`} >
            <FaLock className="icon" />
            <span>Privacy Policy</span>
          </Link>
        </div>
        <div className="menu-item">
          <Link to={`/terms-and-conditions?shop=${encodeURIComponent(shop)}`} >
            <FaFileContract className="icon" />
            <span>Terms and Conditions</span>
          </Link>
        </div>

         <div className="menu-item">
          <Link to={`/cancellation-policy?shop=${encodeURIComponent(shop)}`} >
            <FaUndo className="icon" />
            <span>Cancellation and Refund</span>
          </Link>
        </div>
        <div className="menu-item">
          <Link to={`/shipping-policy?shop=${encodeURIComponent(shop)}`} >
            <FaShippingFast className="icon" />
            <span>Shipping and Delivery</span>
          </Link>
        </div>
         

        <div className="menu-item">
          <Link to={`/contact-us?shop=${encodeURIComponent(shop)}`} >
            <FaEnvelope className="icon" />
            <span>Contact Us</span>
          </Link>
        </div>


        
        <div className="menu-item">
          <div onClick={toggleFounderInfo} className="founder-link">
            <FaPhoneAlt className="icon" />
            <span>Call a Founder</span>
          </div>
          {isFounderOpen && (
            <div className="founder-info">
              <p>Name: Rajprit Sahni</p> {/* Replace with actual name */}
              <p>Phone: +91 81699 32616</p> {/* Replace with actual phone number */}
            </div>
          )}
        </div>

      </div>
    </div>
  );
};

export default Sidebar;
