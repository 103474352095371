import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useLocation, Link } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import '../style/products.css';

const Products = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const shopId = queryParams.get('shop');

  const [designs, setDesigns] = useState([]);
  const [loading, setLoading] = useState(true);
  
  // Pagination State
  const [currentPage, setCurrentPage] = useState(1); // NEW
  const designsPerPage = 7; // NEW

  useEffect(() => {
    const fetchDesigns = async () => {
      try {
        if (!shopId) {
          throw new Error("Shop ID is missing");
        }

        const designsCollection = collection(db, 'shops', shopId, 'designs');
        const designSnapshot = await getDocs(designsCollection);
        const designList = designSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setDesigns(designList);
      } catch (error) {
        console.error("Error fetching designs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDesigns();
  }, [shopId]);

  // Calculate pagination
  const totalPages = Math.ceil(designs.length / designsPerPage); // NEW
  const indexOfLastDesign = currentPage * designsPerPage; // NEW
  const indexOfFirstDesign = indexOfLastDesign - designsPerPage; // NEW
  const currentDesigns = designs.slice(indexOfFirstDesign, indexOfLastDesign); // NEW

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // NEW
  };

  if (loading) {
    return <div className="loading-message">Loading...</div>;
  }

  return (
    <div className="products-page">
      <Header />
      <div className="products-container">
        <Sidebar />
        <div className="main-content">
          <Link to={`/selecttshirt?shop=${shopId}`} className="create-button">Create Product</Link>
          {designs.length === 0 ? (
            <p>No designs uploaded yet for this shop.</p>
          ) : (
            <>
              <table className="design-table">
                <thead>
                  <tr>
                    <th>Design Name</th>
                    <th>Description</th>
                    <th>Product Name</th>
                    <th>Front Mockup</th>
                    <th>Back Mockup</th>
                    <th>Print Cost</th>
                    <th>Selling Price</th>
                    <th>Total Price</th>
                  </tr>
                </thead>
                <tbody>
                  {currentDesigns.map(design => (
                    <tr key={design.id}>
                      <td>{design.designName}</td>
                      <td>{design.description}</td>
                      <td>{design.productName}</td>
                      <td><img src={design.images.front} alt="Front Design" width="50" /></td>
                      <td><img src={design.images.back} alt="Back Design" width="50" /></td>
                      <td>Front Print: {design.costs.front} <br></br> Back Print: {design.costs.back}</td>
                      <td>{design.sellingPrice}</td>
                      <td>{design.totalPrice}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* Pagination Controls */}
              <div className="pagination"> {/* NEW */}
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => handlePageChange(index + 1)}
                    className={currentPage === index + 1 ? 'active' : ''} // NEW
                  >
                    {index + 1}
                  </button>
                ))}
              </div> {/* NEW */}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Products;
