import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import { Storage } from '../firebase';
import { collection, getDocs, updateDoc, doc, query, where, getDoc, addDoc, Timestamp, deleteDoc } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import '../style/OrdersPage.css';
import axios from 'axios';
import ImageModal from '../components/ImageModal'; // Import the ImageModal component
// import { jsPDF } from 'jspdf';
// import autoTable from "jspdf-autotable";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, listAll } from 'firebase/storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Wallet from '../components/Wallet';
import { useModal } from '../components/ModalContext';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { numberToWords } from "amount-to-words";

const OrdersPage = () => {
    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null); // State to track selected image for modal
    const [confirmedOrders, setConfirmedOrders] = useState([]); // To track confirmed orders
    const [selectedOrder, setSelectedOrder] = useState(null);   // Order to confirm
    const [confirmBalance, setConfirmBalance] = useState(null); // Balance after deduction
    const [showConfirmModal, setShowConfirmModal] = useState(false); // Modal state
    const { isModalOpen, setIsModalOpen } = useModal();

    const [showCancelModal, setShowCancelModal] = useState(false); // State for cancel confirmation modal
const [orderToCancel, setOrderToCancel] = useState(null); // Store the order ID to cancel

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const user = auth.currentUser;
    const shopId = queryParams.get('shop');


    const [currentPage, setCurrentPage] = useState(1);
    const ordersPerPage = 7; // Orders per page
    

    const fetchDesignDetailsBySKU = async (sku) => {
        if (!sku) {
            console.error("No SKU provided");
            return null;
        }

        try {
            console.log(`Fetching design data for SKU: ${sku}`);
            const designsCollection = collection(db, 'shops', shopId, 'designs');
            const q = query(designsCollection, where('SKUs', 'array-contains', sku));
            const designSnapshot = await getDocs(q);

            if (!designSnapshot.empty) {
                const designData = designSnapshot.docs[0].data();
                console.log("Design data found:", designData);
                return {
                    ...designData,
                    frontImage: designData.images.front,
                    backImage: designData.images.back,
                };
            } else {
                console.warn(`No design found for SKU: ${sku}`);
                return null;
            }
        } catch (error) {
            console.error("Error fetching design details by SKU:", error);
            return null;
        }
    };


    const fetchOrders = async () => {
        if (!shopId) {
            console.error("Shop ID is missing");
            setLoading(false);
            return;
        }
    
        try {
            const ordersCollection = collection(db, 'shops', shopId, 'orders');
            const ordersSnapshot = await getDocs(ordersCollection);
            console.log("Orders snapshot:", ordersSnapshot);
    
            if (ordersSnapshot.empty) {
                console.warn("No orders found in Firestore.");
                setOrders([]);
                return;
            }
    
            const ordersList = await Promise.all(
                ordersSnapshot.docs.map(async (doc) => {
                    const orderData = doc.data();
                    const isPaid = orderData.isPaid || false;
    
                    // Skip paid orders
                    if (isPaid) return null;
    
                    console.log(`Fetched order data for order ID: ${doc.id}`, orderData);
    
                    if (!orderData.order_items || orderData.order_items.length === 0) {
                        console.warn(`Order ID ${doc.id} has no line items.`);
                        return { id: doc.id, ...orderData, order_items: [], isPaid };
                    }
    
                    const lineItemsWithDesigns = await Promise.all(
                        orderData.order_items.map(async (item) => {
                            const designData = await fetchDesignDetailsBySKU(item.sku);
                            return { ...item, design: designData };
                        })
                    );
    
                    return { id: doc.id, ...orderData, order_items: lineItemsWithDesigns };
                })
            );
    
            // Filter out null entries (i.e., paid orders)
            setOrders(ordersList.filter(order => order !== null));
            console.log("Orders List:", ordersList);
        } catch (error) {
            console.error("Error fetching orders:", error);
        } finally {
            setLoading(false);
        }
    };


    const handleConfirmOrder = async (orderId) => {
        try {
            const order = orders.find(order => order.id === orderId);
            if (!order) {
                toast.error('Order not found');
                return;
            }
    
            // Check if the order is already confirmed
            if (confirmedOrders.includes(orderId)) {
                toast.info('This order is already paid.');
                return;
            }

            if (order.isPaid) {
                toast.info('This order is already paid.'); // Notify if already paid
                return;
            }
        

    
            const totalCost = order.order_items.reduce((sum, item) => {
                return sum + (item.design?.totalPrice || 0) * item.units;
            }, 0);


            // Fetch user's wallet balance
            const walletRef = doc(db, 'users', auth.currentUser.uid, 'wallet', 'walletDetails');
            const walletSnap = await getDoc(walletRef);
    
            if (!walletSnap.exists()) {
                toast.error('You need to recharge', { onClose: () => setIsModalOpen(true) });
                return;
            }
    
            const walletData = walletSnap.data();
            const currentBalance = walletData.balance;
    
            // Check if the balance is sufficient
            if (currentBalance < totalCost) {
                toast.error('Insufficient balance. Recharge the amount.', { onClose: () => setIsModalOpen(true) });
                return;
            }
    
            // Set selected order and show the confirmation modal
            setSelectedOrder(order);
            setConfirmBalance(currentBalance - totalCost); // Calculate balance after deduction
            setShowConfirmModal(true);
    
        } catch (error) {
            console.error('Error confirming order:', error);
            toast.error('Error confirming order:', error);
        }
    };

    const handleCancelOrderClick = (orderId) => {
        setOrderToCancel(orderId); // Set the order to cancel
        setShowCancelModal(true); // Show the cancel confirmation modal
    };
    

    const confirmCancelOrder = async () => {
        try {
            const orderRef = doc(db, 'shops', shopId, 'orders', orderToCancel);
            await deleteDoc(orderRef); // Use deleteDoc to remove the order
            fetchOrders(); // Refresh the order list
            toast.success('Order canceled successfully.');
        } catch (error) {
            console.error("Error canceling order:", error);
            toast.error('Error canceling order.');
        } finally {
            setShowCancelModal(false); // Close the cancel modal
            setOrderToCancel(null); // Clear the order to cancel
        }
    };

    const handleCancelOrder = async (orderId) => {
        try {
            const orderRef = doc(db, 'shops', shopId, 'orders', orderId);
            await deleteDoc(orderRef); // Use deleteDoc to remove the order
            fetchOrders(); // Refresh the order list
        } catch (error) {
            console.error("Error canceling order:", error);
        }
    };

    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc); // Set the clicked image as the selected one
    };

    const handleCloseModal = () => {
        setSelectedImage(null); // Close the modal by clearing the selected image
    };

    useEffect(() => {
        fetchOrders();
    }, [shopId]);

    if (loading) {
        return <div>Loading orders...</div>;
    }

    // if (orders.length === 0) {
    //     return <div>No orders found.</div>;
    // }

    const totalPages = Math.ceil(orders.length / ordersPerPage);
    const startIndex = (currentPage - 1) * ordersPerPage;
    const paginatedOrders = orders.slice(startIndex, startIndex + ordersPerPage);
    

    const confirmOrder = async () => {
        try {
            const walletRef = doc(db, 'users', auth.currentUser.uid, 'wallet', 'walletDetails');
    

            const totalCost = selectedOrder.order_items.reduce((sum, item) => {
                return sum + (item.design?.totalPrice || 0) * item.units + 100;
            }, 0); // Add shipping cost
    
            // Fetch user's current wallet balance
            const walletSnap = await getDoc(walletRef);
            if (!walletSnap.exists()) {
                toast.error('You need to recharge', { onClose: () => setIsModalOpen(true) });
                return;
            }
            const walletData = walletSnap.data();
            const currentBalance = walletData.balance;
    
            // Display current balance and the total cost for clarity
            console.log(`Current Balance: ₹${currentBalance}`);
            console.log(`Total Cost: ₹${totalCost}`);
    
            // Check if the balance is sufficient
            if (currentBalance < totalCost) {
                toast.error('Insufficient balance. Recharge the amount.', { onClose: () => setIsModalOpen(true) });
                return;
            }
    
            // Fetch order data for Shiprocket
            const orderRef = doc(db, 'shops', shopId, 'orders', selectedOrder.id);
            const orderSnapshot = await getDoc(orderRef);
            const orderData = { id: selectedOrder.id, ...orderSnapshot.data() };
            console.log("Fetched order data for Shiprocket:", orderData);
    
            // Create order on Shiprocket
            console.log("Creating order on Shiprocket...");
            const response = await axios.post('https://ufb-1.onrender.com/create-shiprocket-order', orderData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log('Order created on Shiprocket successfully:', response.data);
    
            // Deduct the total cost from the current balance
            const updatedBalance = currentBalance - totalCost;
    
            // Update wallet balance
            console.log("Updating wallet balance...");
            await updateDoc(walletRef, { balance: updatedBalance });
            console.log("Wallet balance updated successfully.");
    
            // Record the transaction
            const transactionsRef = collection(walletRef, 'transactions');
            const transactionData = {
                type: selectedOrder.id,
                amount: totalCost,
                balanceBefore: currentBalance,
                balanceAfter: updatedBalance,
                productName: selectedOrder.order_items.map(item => item.design?.designName).join(', '),
                timestamp: Timestamp.now(),
            };
            console.log("Recording transaction data:", transactionData);
            await addDoc(transactionsRef, transactionData);
            console.log("Transaction recorded successfully.");
    
            // Mark the order as confirmed and paid
            await updateDoc(orderRef, {
                status: 'confirmed',
                isPaid: true // Update the order to indicate it's paid
            });
            console.log("Order status updated successfully.");
    
            // Mark the order as confirmed
            setConfirmedOrders([...confirmedOrders, selectedOrder.id]);
    
            toast.success('Order confirmed');
            setTimeout(() => {
                setShowConfirmModal(false); // Close modal
            }, 500);// Close modal
            fetchOrders(); // Refresh the orders
    
        } catch (error) {
            console.error('Error in confirmOrder function:', error);
            
            // Handle error response specifically for Shiprocket
            if (error.response) {
                console.error('Error response from Shiprocket:', error.response.data);
                toast.error(`Error: ${error.response.data.error || 'An unexpected error occurred.'}`);
            } else {
                console.error('Error confirming order:', error);
                toast.error('An error occurred while confirming the order.');
            }
        }
    };

    // const confirmOrder = async () => {
    //     try {
    //         // Initialize references
    //         const walletRef = firestoreDoc(db, 'users', auth.currentUser.uid, 'wallet', 'walletDetails');
            
    //         // Calculate total cost (including shipping)
    //         const totalCost = selectedOrder.order_items.reduce((sum, item) => {
    //             return sum + (item.design?.totalPrice || 0) * item.units + 100; // Adding shipping
    //         }, 0);
    
    //         // Fetch the user's wallet details
    //         const walletSnap = await getDoc(walletRef);
    //         if (!walletSnap.exists()) {
    //             toast.error('You need to recharge', { onClose: () => setIsModalOpen(true) });
    //             return;
    //         }
    
    //         const walletData = walletSnap.data();
    //         const currentBalance = walletData.balance;
    
    //         // Check if the balance is sufficient
    //         if (currentBalance < totalCost) {
    //             toast.error('Insufficient balance. Recharge the amount.', { onClose: () => setIsModalOpen(true) });
    //             return;
    //         }
    
    //         // Fetch shop data (brand details) from Firestore
    //         const shopRef = firestoreDoc(db, 'shops', shopId); // Assuming `shopId` is available
    //         const shopSnap = await getDoc(shopRef);
    //         if (!shopSnap.exists()) {
    //             toast.error('Shop information not found.');
    //             return;
    //         }
    //         const shopData = shopSnap.data();
    
    //         // Extract brand-specific details from the shop document
    //         // const brandInfo = {
    //         //     brandName: shopData.brandName || 'Default Brand Name',
    //         //     address: shopData.address || 'N/A',
    //         //     pinCode: shopData.pinCode || 'N/A',
    //         //     gstNumber: shopData.gstNumber || 'N/A',
    //         //     contact: shopData.contact || 'N/A',
    //         // };
        
    //         // const MainInfo = {
    //         //     name: 'UNITEE STUDIOS PRIVATE LIMITED',

    //         // };
        
    //         // // Initialize jsPDF document
    //         // const doc = new jsPDF();
    //         // doc.setFontSize(20);
    //         // doc.text('Tax Invoice', 105, 15, null, null, 'center');

    //         // doc.setFontSize(12);
    //         // doc.text(`Consignee (Ship to):`, 10, 30);
    //         // // Consignee and Brand Information
    //         // doc.setFontSize(12);
    //         // doc.text(`Consignee (Ship to):`, 10, 30);
    //         // doc.text(`Name: ${brandInfo.brandName}`, 10, 35);
    //         // doc.text(`Address: ${brandInfo.address}`, 10, 40);
    //         // doc.text(`GST Number: ${brandInfo.gstNumber}`, 10, 45);
    //         // doc.text(`Contact: ${brandInfo.contact}`, 10, 50);
        
    //         // doc.text(`Brand (Bill to):`, 10, 60);
    //         // doc.text(`Name: ${brandInfo.brandName}`, 10, 65);
    //         // doc.text(`Address: ${brandInfo.address}`, 10, 70);
    //         // doc.text(`Pin Code: ${brandInfo.pinCode}`, 10, 75);
    //         // doc.text(`GST Number: ${brandInfo.gstNumber}`, 10, 80);
    //         // doc.text(`Contact: ${brandInfo.contact}`, 10, 85);
        
    //         // // Invoice Details
    //         // const invoiceNo = 'UC/2024-25/004'; // Placeholder
    //         // const invoiceDate = '11-Nov-24'; // Placeholder
    //         // doc.text(`Invoice No: ${invoiceNo}`, 150, 30);
    //         // doc.text(`Date: ${invoiceDate}`, 150, 35);
        
    //         // // Items Table Header
    //         // doc.autoTable({
    //         //     startY: 90,
    //         //     head: [['Sl No.', 'Description of Goods', 'HSN/SAC', 'Quantity', 'Rate', 'Amount']],
    //         //     body: selectedOrder.order_items.map((item, index) => [
    //         //         index + 1,
    //         //         item.design?.designName || 'Unknown',
    //         //         '6109', // HSN Code placeholder
    //         //         item.units,
    //         //         item.rate.totalPrice || 'N/A',
    //         //         item.units * (item.rate.totalPrice || 0)
    //         //     ]),
    //         //     theme: 'grid',
    //         //     styles: { fontSize: 10 },
    //         // });
        
    //         // // Tax Details Summary Table
    //         // const yPosition = doc.autoTable.previous.finalY + 10;
    //         // doc.text('HSN/SAC Summary:', 10, yPosition);
    //         // doc.autoTable({
    //         //     startY: yPosition + 5,
    //         //     head: [['HSN/SAC', 'Taxable Value', 'CGST Rate', 'CGST Amount', 'SGST Rate', 'SGST Amount', 'Total Tax Amount']],
    //         //     body: [
    //         //         ['6109', totalCost.toFixed(2), '2.5%', (totalCost * 0.025).toFixed(2), '2.5%', (totalCost * 0.025).toFixed(2), (totalCost * 0.05).toFixed(2)]
    //         //     ],
    //         //     theme: 'grid',
    //         //     styles: { fontSize: 10 },
    //         // });
        
    //         // // Total Amount Section
    //         // const totalYPosition = doc.autoTable.previous.finalY + 10;
    //         // doc.text(`Total Amount (in words): INR ${toWords(totalCost + totalCost * 0.05)} Only`, 10, totalYPosition);
    //         // doc.text(`Total: ₹${totalCost.toFixed(2)}`, 150, totalYPosition);
            
    //         const brandInfo = {
    //             brandName: shopData.brandName || 'Default Brand Name',
    //             address: shopData.address || 'N/A',
    //             state: shopData.state || 'N/A',         
    //             pinCode: shopData.pincode || 'N/A',     
    //             gstNumber: shopData.gstNumber || 'N/A',
    //             contact: shopData.phoneNumber || 'N/A',
    //         };
    
    //         const mainInfo = {
    //             name: 'UNITEE STUDIOS PRIVATE LIMITED',
    //         };
    
    //         const invoiceDate = new Date().toLocaleDateString('en-GB', {
    //             day: '2-digit',
    //             month: 'short',
    //             year: '2-digit',
    //         });
    

    //         const storageRef = ref(Storage, 'invoices/');

    //         // Get the list of items (invoices) in the 'invoices/' folder
    //         const listResult = await listAll(storageRef);
    //         let nextInvoiceNumber = 1;  // Default to 1 if no invoices exist
            
    //         if (listResult.items.length > 0) {
    //             // Extract the numeric part of each file name (e.g., 'invoice_001.pdf')
    //             const invoiceNumbers = listResult.items
    //                 .map(item => item.name.match(/invoice_(\d+)\.pdf$/))  // Adjusting regex to capture invoice number
    //                 .filter(match => match)  // Filter out invalid names
    //                 .map(match => parseInt(match[1], 10));  // Parse the numeric part of the invoice number
            
    //             // Find the highest invoice number and increment it
    //             if (invoiceNumbers.length > 0) {
    //                 nextInvoiceNumber = Math.max(...invoiceNumbers) + 1;  // Increment the highest invoice number by 1
    //             }
    //         }
            
    //         // Format the invoice number (e.g., 'UC/2024-25/001')
    //         const invoiceNumber = `UC/2024-25/${String(nextInvoiceNumber).padStart(3, '0')}`;
            
    //         // Rest of the code for creating the invoice and uploading it...
            

    //         const doc = new jsPDF();

    //         // Header Section
    //         doc.setFontSize(20);
       
    //         doc.text('TAX INVOICE', 150, 15, null, null, 'center');
            
    //         // Company Information
    //         doc.setFontSize(10);
    //         doc.setFont("Helvetica", "normal");
    //         doc.text("UNITEE STUDIOS PRIVATE LIMITED", 10, 20);
    //         doc.text("Maharashtra, India", 10, 25);
    //         doc.text("GSTIN 27AADCU3575G1Z5", 10, 30);
    //         doc.text("rajprit@uniteeculture.com", 10, 35);
    //         doc.text("1ST FLOOR, PLOT 304/E/, 3, WAKHARIA BUILDING Vithalbhai Patel Road", 10, 40);
    //         doc.text("MUMBAI", 10, 45);
            
    //         // Invoice Details
    //         doc.text("Invoice Details", 150, 25, null, null, 'center');
    //         doc.setFontSize(10);
    //         doc.text(`Invoice Date: ${invoiceDate}`, 140, 30);
    //         doc.text(`Invoice Number: ${invoiceNumber}`, 140, 35);
    //         // doc.text(`Terms: Due On Receipt`, 140, 40);
    //         // doc.text("Due Date: 11/11/2024", 140, 45);
    //         // doc.text("Place of Supply: Maharashtra (27)", 140, 50);
            
    //         // Billing and Shipping Information
    //         doc.text("Bill To", 10, 60);
    //         doc.text(`Name: ${brandInfo.brandName}`, 10, 65);
    //         doc.text(`Address: ${brandInfo.address}`, 10, 70);
    //         doc.text(`State: ${brandInfo.state}`, 10, 75);
    //         doc.text(`Pin Code: ${brandInfo.pinCode}`, 10, 80);
    //         doc.text(`GST Number: ${brandInfo.gstNumber}`, 10, 85);
            
    //         doc.text("Ship To", 140, 60);
    //         doc.text(`Name: ${brandInfo.brandName}`, 140, 65);
    //         doc.text(`Address: ${brandInfo.address}`, 140, 70);
    //         doc.text(`State: ${brandInfo.state}`, 140, 75);
    //         doc.text(`Pin Code: ${brandInfo.pinCode}`, 140, 80);
    //         doc.text(`GST Number: ${brandInfo.gstNumber}`, 140, 85);
            
    //         // Item Table Header
    //         const itemsTableY = 90;
    //         autoTable(doc, {
    //             startY: itemsTableY,
    //             head: [['#', 'Item & Description', 'HSN/SAC', 'Qty', 'Rate', 'CGST %', 'CGST Amt', 'SGST %', 'SGST Amt', 'Amount']],
    //             body: selectedOrder.order_items.map((item, index) => {
    //                 const cgstAmt = (totalCost * item.units * 0.025).toFixed(2); // Calculating CGST at 2.5%
    //                 const sgstAmt = (totalCost * item.units * 0.025).toFixed(2); // Calculating SGST at 2.5%
    //                 const amount = (totalCost * item.units + parseFloat(cgstAmt) + parseFloat(sgstAmt)).toFixed(2);
    //                 return [
    //                     index + 1,
    //                     item.design?.designName || 'Unknown',
    //                     '610910',             // Example HSN code
    //                     item.units,
    //                     totalCost.toFixed(2),
    //                     '2.5%', cgstAmt,
    //                     '2.5%', sgstAmt,
    //                     amount,
    //                 ];
    //             }),
    //             theme: 'grid',
    //             styles: { fontSize: 10 },
    //         });
            
    //         // Tax and Summary Table
    //         let totalTaxableValue = 0;
    //         let totalCGST = 0;
    //         let totalSGST = 0;
    //         selectedOrder.order_items.forEach(item => {
    //             const taxableValue = totalCost * item.units;
    //             const cgst = taxableValue * 0.025;
    //             const sgst = taxableValue * 0.025;
    //             totalTaxableValue += taxableValue;
    //             totalCGST += cgst;
    //             totalSGST += sgst;
    //         });
            
    //         const taxSummaryY = doc.autoTable.previous.finalY + 10;
    //         autoTable(doc, {
    //             startY: taxSummaryY,
    //             head: [['HSN/SAC', 'Taxable Value', 'CGST Rate', 'CGST Amount', 'SGST Rate', 'SGST Amount', 'Total Tax Amount']],
    //             body: [
    //                 ['610910', totalTaxableValue.toFixed(2), '2.5%', totalCGST.toFixed(2), '2.5%', totalSGST.toFixed(2), (totalCGST + totalSGST).toFixed(2)]
    //             ],
    //             theme: 'grid',
    //             styles: { fontSize: 10 },
    //         });
            
    //         // Final Amount Summary
    //         const totalAmount = totalTaxableValue + totalCGST + totalSGST;
    //         const finalAmountY = doc.autoTable.previous.finalY + 10;
    //         doc.text(`Amount Chargeable (in words): Indian Rupee ${numberToWords(totalAmount)} Only`, 10, finalAmountY);
    //         doc.text(`Total: ${totalAmount.toFixed(2)}`, 150, finalAmountY + 5);
    //         doc.text(`Balance Due: ${totalAmount.toFixed(2)}`, 150, finalAmountY + 10);
            
    //         // Footer Notes
    //         doc.text("Thanks for your business.", 10, finalAmountY + 25);
    //         doc.text("Authorized Signature", 150, finalAmountY + 25);
            
            
    
    //         // Save the invoice as a PDF file
    //         // const invoiceFileName = `invoice_${selectedOrder.id}.pdf`;
    //         // const pdfBlob = doc.output('blob');

    //         const invoiceFileName = `invoice_${nextInvoiceNumber}.pdf`;  // Updated to use nextInvoiceNumber
    //         const pdfBlob = doc.output('blob');  // Generate the PDF blob as usual
    
    //         // Upload the invoice to Firebase Storage
    //         const invoiceRef = ref(Storage, `invoices/${invoiceFileName}`);
    //         const uploadTask = uploadBytesResumable(invoiceRef, pdfBlob);
    
    //         // Observe the upload task
    //         uploadTask.on(
    //             'state_changed',
    //             snapshot => {
    //                 // You can handle upload progress here (e.g., using a progress bar)
    //                 const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //                 console.log(`Upload is ${progress}% done`);
    //             },
    //             error => {
    //                 console.error('Error uploading invoice:', error);
    //                 toast.error('Error uploading invoice');
    //             },
    //             async () => {
    //                 try {
    //                     // After upload is complete, get the download URL
    //                     const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
    //                     console.log('Invoice uploaded, download URL:', downloadURL);
    
    //                     // Fetch order data for Shiprocket
    //                     const orderRef = firestoreDoc(db, 'shops', shopId, 'orders', selectedOrder.id);
    //                     const orderSnapshot = await getDoc(orderRef);
    //                     const orderData = { id: selectedOrder.id, ...orderSnapshot.data() };
    
    //                     // Create order on Shiprocket
    //                     console.log("Creating order on Shiprocket...");
    //                     const response = await axios.post('https://aa8d-114-143-243-34.ngrok-free.app/create-shiprocket-order', orderData, {
    //                         headers: {
    //                             'Content-Type': 'application/json',
    //                         },
    //                     });
    //                     console.log('Order created on Shiprocket successfully:', response.data);
    
    //                     // Deduct the total cost from the current balance
    //                     const updatedBalance = currentBalance - totalCost;
    
    //                     // Update wallet balance
    //                     console.log("Updating wallet balance...");
    //                     await updateDoc(walletRef, { balance: updatedBalance });
    //                     console.log("Wallet balance updated successfully.");
    
    //                     // Record the transaction
    //                     const transactionsRef = collection(walletRef, 'transactions');
    //                     const transactionData = {
    //                         type: selectedOrder.id,
    //                         amount: totalCost,
    //                         balanceBefore: currentBalance,
    //                         balanceAfter: updatedBalance,
    //                         productName: selectedOrder.order_items.map(item => item.design?.designName).join(', '),
    //                         timestamp: Timestamp.now(),
    //                     };
    //                     console.log("Recording transaction data:", transactionData);
    //                     await addDoc(transactionsRef, transactionData);
    //                     console.log("Transaction recorded successfully.");
    
    //                     // Mark the order as confirmed and paid
    //                     await updateDoc(orderRef, {
    //                         status: 'confirmed',
    //                         isPaid: true, // Update the order to indicate it's paid
    //                         invoiceUrl: downloadURL // Store the invoice URL
    //                     });
    //                     console.log("Order status updated successfully.");
    
    //                     // Mark the order as confirmed
    //                     setConfirmedOrders([...confirmedOrders, selectedOrder.id]);
    
    //                     toast.success('Order confirmed and invoice generated!');
    //                     setShowConfirmModal(false); // Close modal
    //                     fetchOrders(); // Refresh the orders
    //                 } catch (err) {
    //                     console.error('Error handling completed upload:', err);
    //                     toast.error('Error handling completed upload.');
    //                 }
    //             }
    //         );
    //     } catch (error) {
    //         console.error('Error in confirmOrder function:', error);
    
    //         // Handle error response specifically for Shiprocket
    //         if (error.response) {
    //             console.error('Error response from Shiprocket:', error.response.data);
    //             toast.error(`Error: ${error.response.data.error || 'An unexpected error occurred.'}`);
    //         } else {
    //             console.error('Error confirming order:', error);
    //             toast.error('An error occurred while confirming the order.');
    //         }
    //     }
    // };
    

    let totalCost = 0; // Define totalCost here

    if (showConfirmModal && selectedOrder) {
        totalCost = selectedOrder.order_items.reduce((sum, item) => 
            sum + (item.design?.totalPrice || 0) * item.units + 100, 0
        ) ;
    }

    return (
        <div className="orders-page">
            <Header />
            <div className="orders-container">
                <Sidebar />
                <div className="main-content">
                    <div className='ordersp'>Confirm your orders, and we'll start printing right away!</div>
                    <h1>Orders</h1>

                    {showConfirmModal && selectedOrder && (
                        <div className="modals">
                            <div className="modal-contents">
                                <h3>Confirm Order</h3>
                                <p>
                                    Are you sure you want to confirm the order for{' '}
                                    {selectedOrder.order_items.map(item => item.design?.designName).join(', ')}?
                                </p>
                                <p>
                                    Current Balance: ₹{
                                        confirmBalance + selectedOrder.order_items.reduce((sum, item) => {
                                            const itemTotal = (item.design?.totalPrice || 0) * item.units;
                                            return sum + itemTotal;
                                        }, 0)
                                    }
                                </p>
                                <p>Total Cost: ₹{totalCost}</p>
                                <p>Balance After Deduction: ₹{confirmBalance + selectedOrder.order_items.reduce((sum, item) => {
                                            const itemTotal = (item.design?.totalPrice || 0) * item.units;
                                            return sum + itemTotal;
                                        }, 0) - totalCost}</p>
                                <button onClick={confirmOrder}>Yes, Confirm</button>
                                <button onClick={() => setShowConfirmModal(false)}>Cancel</button>
                            </div>
                        </div>
                    )}

                    {showCancelModal && (
                        <div className="modals">
                            <div className="modal-contents">
                                <h3>Cancel Order</h3>
                                <p>Are you sure you want to cancel this order?</p>
                                <button onClick={confirmCancelOrder}>Yes, Cancel</button>
                                <button onClick={() => setShowCancelModal(false)}>No</button>
                            </div>
                        </div>
                    )}

                    <ToastContainer />
                    {isModalOpen && <Wallet />}
                    <table className="orders-table">
                        <thead>
                            <tr>
                                <th>Order ID</th>
                                <th>Design Name</th>
                                {/* <th>SKU</th> */}
                                <th>Quantity</th>
                                <th>GSM</th>
                                <th>Selling Price</th>
                                <th>Items Ordered</th>
                                <th>Front Image</th>
                                <th>Back Image</th>
                                <th>Base Cost</th>
                                <th>Print Cost</th>
                                <th>Shipping Cost</th>
                                <th>Total Cost</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            
                            {paginatedOrders.map((order) => (
                                <tr key={order.id}>
                                    <td>{order.id}</td>
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.design?.designName || 'Unknown'}</p>
                                        ))}
                                    </td>
                                    {/* <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.sku}</p>
                                        ))}
                                    </td> */}
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.units}</p>
                                        ))}
                                    </td>
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.design?.productGSM || 'N/A'}</p>
                                        ))}
                                    </td>
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.selling_price}</p>
                                        ))}
                                    </td>
                                    <td>{order.order_items.reduce((sum, item) => sum + item.units, 0)}</td>
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>
                                                {item.design?.frontImage ? (
                                                    <img src={item.design.frontImage} alt="Front Design" style={{ width: '50px', height: 'auto' }} onClick={() => handleImageClick(item.design.frontImage)} />
                                                ) : (
                                                    'N/A'
                                                )}
                                            </p>
                                        ))}
                                    </td>
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>
                                                {item.design?.backImage ? (
                                                    <img src={item.design.backImage} alt="Back Design" style={{ width: '50px', height: 'auto' }} onClick={() => handleImageClick(item.design.backImage)} />
                                                ) : (
                                                    'N/A'
                                                )}
                                            </p>
                                        ))}
                                    </td>
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.design?.baseCost || 'N/A'}</p>
                                        ))}
                                    </td>
                                    <td>
                                        {order.order_items.map((item, index) => (
                                            <p key={index}>{item.design?.PrintCost || 'N/A'}</p>
                                        ))}
                                    </td>
                                    <td>
                                        {order.order_items.map((item) => (
                                            <p key={item.sku}>100</p> // Static shipping cost
                                        ))}
                                    </td>
                                    <td>
                                        {order.order_items.reduce((sum, item) => sum + (item.design?.totalPrice || 0) * item.units + 100, 0)} {/* Include shipping */}
                                    </td>
                                    <td>
                                        <button className="confirm-button" onClick={() => handleConfirmOrder(order.id)} disabled={order.isPaid}>
                                            {order.isPaid ? 'Paid' : 'Confirm'}
                                        </button>
                                        <button className="cancel-button" onClick={() => handleCancelOrderClick(order.id)}>Cancel</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="pagination-controls">
                        <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                            Previous
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}>
                            Next
                        </button>
                    </div>
                    <ImageModal imageSrc={selectedImage} onClose={handleCloseModal} />
                </div>
            </div>
        </div>
    );
};

export default OrdersPage;