// import React, { useState } from 'react'; 
// import { db } from '../firebase';
// import { collection, addDoc, Timestamp } from 'firebase/firestore';
// import { useLocation } from 'react-router-dom';
// import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import '../style/OrderSample.css';

// const SampleOrder = () => {
//     const location = useLocation();
//     const queryParams = new URLSearchParams(location.search);
//     const shopId = queryParams.get('shop'); 
//     const totalPrice = queryParams.get('totalPrice');

//     const [name, setName] = useState('');
//     const [email, setEmail] = useState('');  // New email state
//     const [address, setAddress] = useState('');
//     const [city, setCity] = useState('');
//     const [state, setState] = useState('Maharashtra');
//     const [pinCode, setPinCode] = useState('');
//     const [phone, setPhone] = useState('');
//     const [product, setProduct] = useState('blackOversized');
//     const [loading, setLoading] = useState(false);

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setLoading(true);

//         // Create order data
//         const orderData = {
//             customer: {
//                 name,
//                 email,  // Include email in order data
//                 address,
//                 city,
//                 state,
//                 pinCode,
//                 phone,
//             },
//             product,
//             createdAt: Timestamp.now(),
//             status: 'pending',
//             isPaid: false,
//             totalPrice: totalPrice,
//         };

//         try {
//             // Save order to Firestore
//             const ordersCollection = collection(db, 'shops', shopId, 'orders');
//             await addDoc(ordersCollection, orderData);
//             toast.success('Order created successfully!');

//             // Send order data to create-sampleshiprocket-order
//             const response = await axios.post('https://0b8c-114-143-243-34.ngrok-free.app/create-sampleshiprocket-order', {
//                 customer: orderData.customer,
//                 product: orderData.product,
//                 totalPrice: orderData.totalPrice,
//             });
//             console.log('Sample order created on Shiprocket:', response.data);
//         } catch (error) {
//             console.error('Error creating order:', error);
//             toast.error('Error creating order.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     return (
//         <div className="sample-order">
//             <h2>Create Sample Order</h2>
//             <form onSubmit={handleSubmit}>
//                 <div>
//                     <label>Name:</label>
//                     <input
//                         type="text"
//                         value={name}
//                         onChange={(e) => setName(e.target.value)}
//                         required
//                     />
//                 </div>
//                 <div>
//                     <label>Email:</label>
//                     <input
//                         type="email"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         required
//                     />
//                 </div>
            
//                 <div>
//                     <label>Address:</label>
//                     <input
//                         type="text"
//                         value={address}
//                         onChange={(e) => setAddress(e.target.value)}
//                         required
//                     />
//                 </div>
//                 <div>
//                     <label>City:</label>
//                     <input
//                         type="text"
//                         value={city}
//                         onChange={(e) => setCity(e.target.value)}
//                         required
//                     />
//                 </div>
//                 <div>
//                     <label>State:</label>
//                     <select value={state} onChange={(e) => setState(e.target.value)}>
//                         <option value="Maharashtra">Maharashtra</option>
//                         {/* Additional states can be added here */}
//                     </select>
//                 </div>
//                 <div>
//                     <label>PIN Code:</label>
//                     <input
//                         type="text"
//                         value={pinCode}
//                         onChange={(e) => setPinCode(e.target.value)}
//                         required
//                     />
//                 </div>
//                 <div>
//                     <label>Phone Number:</label>
//                     <input
//                         type="tel"
//                         value={phone}
//                         onChange={(e) => setPhone(e.target.value)}
//                         required
//                     />
//                 </div>
//                 <button type="submit" disabled={loading}>
//                     {loading ? 'Submitting...' : 'Submit Order'}
//                 </button>
//             </form>
//             <ToastContainer />
//         </div>
//     );
// };

// export default SampleOrder;


import React, { useState, useEffect } from 'react';  
import { db, auth } from '../firebase';
import { collection, addDoc, doc, getDoc, updateDoc, Timestamp } from 'firebase/firestore';
import { useLocation, useNavigate} from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../style/OrderSample.css';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { onAuthStateChanged } from 'firebase/auth';
import Modal from '../components/Modal';

// Modal Component
const ConfirmationModal = ({ totalPrice, onConfirm, onCancel }) => {
    return (
        <div className="modals">
            <div className="modal-contents">
            <h2>Confirm Order</h2>
            <p>Are you sure you want to place this order? Total Price: ₹{totalPrice}</p>
            <button onClick={onConfirm}>Confirm</button>
            <button onClick={onCancel} className='cancel'>Cancel</button>
            </div>
        </div>
    );
};

const SampleOrder = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const shopId = queryParams.get('shop'); 
    const totalPrice = parseFloat(queryParams.get('totalPrice'));
    const designName = queryParams.get('designName');
    const designId = queryParams.get('design'); 

    const [userId, setUserId] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('Maharashtra');
    const [pinCode, setPinCode] = useState('');
    const [phone, setPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [walletBalance, setWalletBalance] = useState(0);
    const [showModal, setShowModal] = useState(false); // Confirmation modal visibility state
    const [showSuccessModal, setShowSuccessModal] = useState(false); // Success modal visibility state
    const navigate = useNavigate();
    
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserId(user.uid);
                setEmail(user.email); // Optionally set the email
            } else {
                toast.error('User not authenticated.');
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const fetchWalletBalance = async () => {
            if (!userId) return;

            try {
                const walletRef = doc(db, 'users', userId, 'wallet', 'walletDetails');
                const walletSnap = await getDoc(walletRef);
                if (walletSnap.exists()) {
                    const walletData = walletSnap.data();
                    setWalletBalance(walletData.balance);
                } else {
                    toast.error('Wallet not found. Please recharge.');
                }
            } catch (error) {
                console.error('Error fetching wallet balance:', error);
                toast.error('Error fetching wallet balance.');
            }
        };

        fetchWalletBalance();
    }, [userId]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (walletBalance < totalPrice) {
            toast.error('Insufficient balance. Please recharge your wallet.');
            return;
        }
        setShowModal(true); // Show the confirmation modal
    };

    // const handleConfirm = async () => {
    //     setLoading(true);
    //     setShowModal(false); // Hide the confirmation modal

    //     const orderData = {
    //         customer: {
    //             name,
    //             email,
    //             address,
    //             city,
    //             state,
    //             pinCode,
    //             phone,
    //         },
    //         product: designName,
    //         designId, 
    //         createdAt: Timestamp.now(),
    //         status: 'pending',
    //         isPaid: false,
    //         totalPrice: totalPrice,
    //     };

    //     try {
    //         const SampleordersCollection = collection(db, 'shops', shopId, 'sampleorders');
    //         const docRef = await addDoc(SampleordersCollection, orderData);
    //         const orderId = docRef.id;

    //         const response = await axios.post('https://0941-202-72-224-25.ngrok-free.app/create-sampleshiprocket-order', {
    //             customer: orderData.customer,
    //             product: orderData.product,
    //             totalPrice: orderData.totalPrice,
    //         });

    //         await updateDoc(docRef, {
    //             orderId: response.data.channel_order_id,
    //             designId: orderData.designId,
    //         });

    //         toast.success('Sample order created successfully!');

    //         const updatedBalance = walletBalance - totalPrice;
    //         const walletRef = doc(db, 'users', userId, 'wallet', 'walletDetails');
    //         await updateDoc(walletRef, { balance: updatedBalance });
    //         setShowSuccessModal(true); // Show success modal after order is placed
    //         console.log('Sample order created on Shiprocket:', response.data);
    //     } catch (error) {
    //         console.error('Error creating order:', error);
    //         toast.error('Error creating order.');
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const handleConfirm = async () => {
        setLoading(true);
        setShowModal(false); // Hide the confirmation modal
    
        const orderData = {
            customer: {
                name,
                email,
                address,
                city,
                state,
                pinCode,
                phone,
            },
            product: designName,
            designId, 
            createdAt: Timestamp.now(),
            status: 'pending',
            isPaid: false,
            totalPrice: totalPrice,
            shopId, // Include shopId in the order data
        };
    
        try {
            // Call the API to create the sample order
            const response = await axios.post('https://ufb-1.onrender.com/create-sampleshiprocket-order', {
                ...orderData,
                shopId, // Ensure shopId is passed as part of the request payload
            });
    
            const { data } = response;
            if (data && data.channel_order_id) {
                // Save orderId and other details to Firestore
                const SampleordersCollection = collection(db, 'shops', shopId, 'sampleorders');
                const docRef = await addDoc(SampleordersCollection, {
                    ...orderData,
                    orderId: data.channel_order_id, // Store Shiprocket order ID
                });
    
                // Also update the wallet balance
                const updatedBalance = walletBalance - totalPrice;
                const walletRef = doc(db, 'users', userId, 'wallet', 'walletDetails');
                await updateDoc(walletRef, { balance: updatedBalance });
    
                toast.success('Sample order created successfully!');
                setShowSuccessModal(true); // Show success modal after order is placed
            } else {
                toast.error('Failed to create the sample order on Shiprocket.');
            }
        } catch (error) {
            console.error('Error creating order:', error);
            toast.error('Error creating order.');
        } finally {
            setLoading(false);
        }
    };
    

    const handleCancel = () => {
        setShowModal(false); // Close the confirmation modal without proceeding
    };

    const handleGoToDashboard = () => {
        navigate(`/dashboard?shop=${encodeURIComponent(shopId)}`);
    };

    return (
        <>
            <Header />
            <div className="products-container">
                <Sidebar />
                <div className="sample-order">
                    <h2>Create Sample Order</h2>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label>Name:</label>
                            <input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>Email:</label>
                            <input
                                type="email"
                                value={email}
                                readOnly 
                            />
                        </div>
                        <div>
                            <label>Address:</label>
                            <input
                                type="text"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>City:</label>
                            <input
                                type="text"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>State:</label>
                            <select value={state} onChange={(e) => setState(e.target.value)}>
                                <option value="Maharashtra">Maharashtra</option>
                                {/* Additional states can be added here */}
                            </select>
                        </div>
                        <div>
                            <label>PIN Code:</label>
                            <input
                                type="text"
                                value={pinCode}
                                onChange={(e) => setPinCode(e.target.value)}
                                required
                            />
                        </div>
                        <div>
                            <label>Phone Number:</label>
                            <input
                                type="tel"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" disabled={loading}>
                            {loading ? 'Submitting...' : 'Submit Order'}
                        </button>
                    </form>

                    {showModal && (
                        <ConfirmationModal 
                            totalPrice={totalPrice} 
                            onConfirm={handleConfirm} 
                            onCancel={handleCancel} 
                        />
                    )}

                    {showSuccessModal && (
                        <Modal>
                            <div className="modal-content">
                                <h2>Your sample order has been created successfully!</h2>
                                <button onClick={handleGoToDashboard}>Go to Dashboard page!</button>
                            </div>
                        </Modal>
                    )}

                    <ToastContainer />
                </div>
            </div>
        </>
    );
};

export default SampleOrder;







