import React, { useState, useEffect, useCallback } from 'react';
import { doc, getDoc, updateDoc, Timestamp, setDoc, addDoc, collection, loadBundle } from 'firebase/firestore';
import { db, auth } from '../firebase';
import '../style/Header.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useModal } from './ModalContext';
import { useLocation } from 'react-router-dom';


const Wallet = () => {
    const [amount, setAmount] = useState('0');
    const [payableAmount, setPayableAmount] = useState(0);
    const [couponAmount, setCouponAmount] = useState(0);
    const [balance, setBalance] = useState();
    const [loading, setLoading] = useState(false);
    const [shopData, setShopData] = useState(null);  // State to store shop data
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const shop = queryParams.get('shop');
    const { setIsModalOpen } = useModal();


    const handleClose = () => {
        setIsModalOpen(false);
    };

    const fetchShopData = async () => {
        setLoading(true);
        if (shopData) {
            // If shopData is already fetched, do not fetch again
            console.log("Shop data already fetched.");
            return;
        }

        try {
            const shopRef = doc(db, "shops", shop); // Replace with dynamic shop ID
            const shopSnap = await getDoc(shopRef);

            if (shopSnap.exists()) {
                setShopData(shopSnap.data());  // Set the fetched shop data
                setLoading(false);  // Set loading to false once data is received
                console.log("Fetched shop data:", shopSnap.data());
            } else {
                console.log("No such document!");
            }
        } catch (error) {
            console.error("Error fetching shop data:", error);
        }
    };
    const user = auth.currentUser;

    useEffect(() => {
        if (user) {
            fetchWalletBalance();
        } else {
            toast.error('User not logged in. Please log in.');
        }
        if (!shopData) {  // Only call the function if data is not already fetched
            fetchShopData();
        }
    }, [user, shopData]);


    const fetchWalletBalance = async () => {
        try {
            const userRef = doc(db, 'users', user.uid, 'wallet', 'walletDetails');
            const docSnap = await getDoc(userRef);

            if (docSnap.exists()) {
                const walletData = docSnap.data();
                setBalance(walletData.balance);
            } else {
                setBalance(0); // If no wallet data exists, default to 0 balance
            }
        } catch (error) {
            console.error('Error fetching wallet balance:', error.message);
            toast.error('Failed to fetch wallet balance. Please try again.');
        }
    };

    const handleWalletPayment = useCallback(() => {
        if (parseFloat(amount) < 100) {
            toast.error('Minimum amount should be ₹100.');
            return;
        }
        setLoading(true);


        const options = {
            key: 'rzp_test_EzMWFi6hXkrJZl',
            
            // Replace with your Razorpay key
            amount: payableAmount * 100, // Amount in paisa (e.g., 100 INR = 10000 paisa)
            currency: 'INR',
            name: shopData.brandName || "Users",
            description: 'Wallet Recharge',
            handler: async (response) => {
                try {
                    // Handle successful payment here
                    const userRef = doc(db, 'users', user.uid, 'wallet', 'walletDetails');
                    const docSnap = await getDoc(userRef);

                    let currentBalance = 0;
                    if (!docSnap.exists()) {
                        // Initialize wallet with zero balance if it does not exist
                        await setDoc(userRef, {
                            balance: 0,
                            lastUpdated: Timestamp.now(),
                        });
                    } else {
                        currentBalance = docSnap.data().balance;
                    }

                    const newBalance = currentBalance + parseFloat(amount);
                    await updateDoc(userRef, {
                        balance: newBalance,
                        lastUpdated: Timestamp.now(),
                        user: shopData.brandName,
                        contact: shopData.phoneNumber,
                        email: shopData.emailAddress,
                    });

                    const transactionsRef = collection(userRef, 'transactions');
                    await addDoc(transactionsRef, {
                        type: 'deposit',
                        amount: parseFloat(amount),
                        balanceBefore: currentBalance,
                        balanceAfter: newBalance,
                        timestamp: Timestamp.now(),
                    });

                    setBalance(newBalance);
                    toast.success('Payment successful and wallet updated!');
                    // setIsModalOp(false)
                    // setIsModalOpen(false);
                    handleClose();
                } catch (error) {
                    console.error('Error in updating wallet after payment:', error.message);
                    toast.error('Failed to update wallet balance. Please try again.');
                } finally {
                    setLoading(false);
                }
            },
            prefill: {
                name: shopData.brandName || 'User',
                email: shopData.emailAddress,
                contact: shopData.phoneNumber || '',
            },
            theme: {
                color: '#3399cc',
            },
            modal: {
                ondismiss: () => {
                    setLoading(false);
                    toast.error('Payment cancelled.');
                },
            },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
    }, [amount, user, payableAmount]);


    const handleAmountChange = (value) => {
        if (value === '') {
            setAmount('');
            setPayableAmount(0);
        } else {
            const floatValue = parseFloat(value);
            if (floatValue >= 0 && floatValue <= 5000000) {
                const roundedValue = Math.floor(floatValue);
                setAmount(roundedValue.toString());
                setPayableAmount(roundedValue - couponAmount);

                if (floatValue !== roundedValue) {
                    const decimal = (floatValue - roundedValue).toFixed(2);
                    toast.warning(`Amount rounded down to ₹${roundedValue}. Decimal value ₹${decimal} ignored.`);
                }
            } else {
                toast.error('Please enter a valid amount between ₹100 and ₹50,00,000.');
            }
        }
    };

    const isPaymentButtonDisabled = parseFloat(amount) < 100 || loading;
    const exit = () => {
        handleClose()

    }

    return (
        <div className="modal-backdrop">
            <ToastContainer />
            <div className="modal-content">
                <div className="modal-header">
                    <h2 className="modal-title">Recharge Your Wallet</h2>
                    <button onClick={() => exit()} className="modal-close">✖</button>
                </div>

                <p className="wallet-amount">Current Wallet Amount ₹{balance}</p>

                <div className="amount-input">
                    <label htmlFor="amount">Enter Amount in Multiples of 100 Below</label>
                    <input
                        type="number"
                        id="amount"
                        value={amount}
                        onChange={(e) => handleAmountChange(e.target.value)}
                        min="100"
                        max="5000000"
                        step="100"
                        className="input-field"
                    />
                    <p className="input-hint">Min value: ₹100 & Max value: ₹50,00,000</p>
                </div>

                <div className="quick-select">
                    {[500, 1000, 2500, 5000, 10000].map((value) => (
                        <button
                            key={value}
                            onClick={() => handleAmountChange(value.toString())}
                            className={`quick-btn ${parseFloat(amount) === value ? 'selected' : ''}`}>
                            ₹{value}
                        </button>
                    ))}
                </div>

                <div className="payment-summary">
                    <div className="summary-item">
                        <span>Recharge Amount</span>
                        <span>₹{amount}</span>
                    </div>
                    <div className="summary-item">
                        <span>Total Amount to be credited</span>
                        <span>₹{amount - couponAmount}</span>
                    </div>
                    <div className="summary-item payable">
                        <span>Payable Amount</span>
                        <span>₹{payableAmount}</span>
                    </div>
                </div>
                <button
                    onClick={handleWalletPayment}
                    className="payment-btn"
                    disabled={isPaymentButtonDisabled}
                >
                    {loading ? 'Processing...' : 'Continue to Payment'}
                </button>

                <ToastContainer position="top-center" autoClose={5000} hideProgressBar />
            </div>
        </div >
    );
};

export default Wallet;